//@flow
const { join } = require('path');

// THIS MUST BE CALLED BEFORE 'config' IS REQUIRED
process.env['NODE_CONFIG_DIR'] = join(__dirname, '..', '..', '..', '/config/');

const config = require('config');

function MalformedError(key, type) {
  this.name = 'MalformedError';
  this.message = `Configuration key '${key}' is malformed. Expected type '${type}'.`;
  this.stack = new Error().stack;
}

// The following checks are needed to create a statically typed configuration object.
//
// NOTE: DO NOT try to add flow types to this file it is a lie.
//       Adding flow types would require any node runtime to understand flow types or
//       to have those flow types removed using `flow-remove-types` but this route is a bit
//       more involved than you might think. Proceed at your own risk.
//       This file still requires the '@flow' header to be picked up by flow at the root level.
//       The following refinments work in all cases.
if (typeof config.dev !== 'boolean') {
  throw new MalformedError('dev', 'boolean');
}
if (typeof config.generateStats !== 'boolean') {
  throw new MalformedError('generateStats', 'boolean');
}
if (typeof config.uploadHost !== 'string') {
  throw new MalformedError('uploadHost', 'boolean');
}
if (
  !Array.isArray(config.domains) ||
  config.domains.some(x => typeof x !== 'string')
) {
  throw new MalformedError('domains', 'string[]');
}
if (typeof config.authHost !== 'string') {
  throw new MalformedError('authHost', 'string');
}
if (typeof config.reportingUrl !== 'string') {
  throw new MalformedError('reportingUrl', 'string');
}
if (typeof config.deployedURL !== 'string') {
  throw new MalformedError('deployedURL', 'string');
}
if (typeof config.horizonAPI !== 'string') {
  throw new MalformedError('horizonAPI', 'string');
}
if (typeof config.sevenhellAPI !== 'string') {
  throw new MalformedError('sevenhellAPI', 'string');
}
if (typeof config.publicAPI !== 'string') {
  throw new MalformedError('publicAPI', 'string');
}
if (typeof config.clonewatchURL !== 'string') {
  throw new MalformedError('clonewatchURL', 'string');
}
if (typeof config.googleStorage !== 'string') {
  throw new MalformedError('googleStorage', 'string');
}
if (typeof config.sentryEnabled !== 'boolean') {
  throw new MalformedError('sentryEnabled', 'boolean');
}
if (typeof config.sentryOrganization !== 'string') {
  throw new MalformedError('sentryOrganization', 'string');
}
if (typeof config.sentryOrganization !== 'string') {
  throw new MalformedError('sentryOrganization', 'string');
}
if (typeof config.sentryPublicKey !== 'string') {
  throw new MalformedError('sentryPublicKey', 'string');
}
if (typeof config.sentryProjectId !== 'number') {
  throw new MalformedError('sentryProjectId', 'number');
}
if (typeof config.sentryHost !== 'string') {
  throw new MalformedError('sentryHost', 'string');
}
if (typeof config.googleClientId !== 'string') {
  throw new MalformedError('googleClientId', 'string');
}
if (typeof config.analyticsId !== 'string') {
  throw new MalformedError('analyticsId', 'string');
}
if (typeof config.analyticsName !== 'string') {
  throw new MalformedError('analyticsName', 'string');
}
if (typeof config.awsClientId !== 'string') {
  throw new MalformedError('awsClientId', 'string');
}

module.exports = {
  dev: config.dev,
  generateStats: config.generateStats,
  uploadHost: config.uploadHost,
  domains: config.domains,
  authHost: config.authHost,
  reportingUrl: config.reportingUrl,
  deployedURL: config.deployedURL,
  horizonAPI: config.horizonAPI,
  sevenhellAPI: config.sevenhellAPI,
  publicAPI: config.publicAPI,
  clonewatchURL: config.clonewatchURL,
  googleStorage: config.googleStorage,
  sentryEnabled: config.sentryEnabled,
  sentryOrganization: config.sentryOrganization,
  sentryPublicKey: config.sentryPublicKey,
  sentryProjectId: config.sentryProjectId,
  sentryHost: config.sentryHost,
  sentryApiKey: process.env['SENTRY_API_KEY'],
  bitbucketCommit: process.env['BITBUCKET_COMMIT'],
  bitbucketBranch: process.env['BITBUCKET_BRANCH'],
  bitbucketBuildNumber: process.env['BITBUCKET_BUILD_NUMBER'],
  googleClientId: config.googleClientId,
  analyticsId: config.analyticsId,
  analyticsName: config.analyticsName,
  awsClientId: config.awsClientId,
};
