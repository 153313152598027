//@flow
import React, { useCallback, useState, useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { palette } from '@dt/theme';
import mobile_apps from '@dt/graphql-support/sevenhell/mobile_apps';
import { useQuery } from '@dt/apollo-link-schema-rest';
import { MobileAppsPlatformIcon } from './MobileAppsPlatformIcon';
import Text from './../Text';
import { Message } from './../Message';
import { AdvancedCheckbox } from './../AdvancedCheckbox';
import type { MobileAppsListQuery } from '@dt/graphql-support/types';
import { MobileAppPlatformValues } from '@dt/graphql-support/types';

const useStyles = makeStyles({
  multipleAppSelectBackground: {
    height: 200,
    maxWidth: 700,
    minWidth: 450,
    backgroundColor: palette.gray50,
    padding: 14,
    paddingTop: 0,
    overflow: 'auto',
  },
});

type Props = {|
  +should_notify_about_all_mobile_apps: boolean,
  +should_notify_about_mobile_app_ids: Array<string>,
  +onChange: (config: {
    should_notify_about_all_mobile_apps: boolean,
    should_notify_about_mobile_app_ids: Array<string>,
  }) => void,
|};

const createInitialRadioGroupSelected = ({
  should_notify_about_all_mobile_apps,
  should_notify_about_mobile_app_ids,
}) => {
  if (should_notify_about_all_mobile_apps) {
    return 'mobile-apps-all';
  } else if (should_notify_about_mobile_app_ids.length <= 0) {
    return 'mobile-apps-none';
  } else {
    return 'mobile-apps-selected';
  }
};

const LookupMobileAppPlatformNameIdentifier: $Exact<
  $ObjMap<typeof MobileAppPlatformValues, () => string>,
> = {
  IOS: 'iOS',
  ANDROID: 'Android',
  ANDROID_AMAZON: 'Android Amazon',
  ANDROID_FACEBOOK_PORTAL: 'Android Facebook Portal',
  IOS_ON_MAC: 'iOS on M1 Mac',
  ANDROID_OCULUS: 'Android Oculus',
  WINDOWS_PHONE: 'Windows Phone',
  MACOS: 'MacOS',
};

/*
 * Returns a mobile app's metadata summary.
 *
 * @param app - Mobile app.
 */
const calculateMobileAppMetadataSummary = app => {
  return `${LookupMobileAppPlatformNameIdentifier[app.platform]}${
    app.most_recent_scan?.app_version
      ? ` | v${app.most_recent_scan.app_version}`
      : ''
  }${app.bundle_id ? ` | ${app.bundle_id}` : ''}`;
};

/*
 * Mobile secure mobile apps selector.
 * Used to provide the user the ability to select all or some of their mobile apps.
 *
 * @param should_notify_about_all_mobile_apps - Whether all are selected.
 * @param should_notify_about_mobile_app_ids - Specificly selected apps.
 */
const MobileAppsSelectorComponent = function MobileAppsSelector({
  should_notify_about_all_mobile_apps,
  should_notify_about_mobile_app_ids,
  onChange,
}: Props) {
  // Fetch mobile applications.
  const {
    data: mobileAppsListData,
    loading: mobileAppsListLoading,
    error: mobileAppsListError,
    fetchMore: mobileAppsListFetchMore,
  } = useQuery<MobileAppsListQuery, _>(mobile_apps.list);
  const pagination_info =
    mobileAppsListData?.mobile_apps_list.pagination_information;
  const mobile_applications = mobileAppsListData?.mobile_apps_list.mobile_apps;

  // Form state.
  const [radioGroupSelected, setRadioGroupSelected] = useState<
    'mobile-apps-all' | 'mobile-apps-none' | 'mobile-apps-selected',
  >(
    createInitialRadioGroupSelected({
      should_notify_about_all_mobile_apps,
      should_notify_about_mobile_app_ids,
    }),
  );

  // Side effects.
  useEffect(() => {
    mobileAppsListFetchMore && mobileAppsListFetchMore();
  }, [mobileAppsListFetchMore]);

  // Event handlers.
  const handleOnChangeRadioGroup = useCallback(
    value => {
      setRadioGroupSelected(value);

      if (value === 'mobile-apps-all') {
        onChange({
          should_notify_about_all_mobile_apps: true,
          should_notify_about_mobile_app_ids: [],
        });
      } else if (value === 'mobile-apps-none') {
        onChange({
          should_notify_about_all_mobile_apps: false,
          should_notify_about_mobile_app_ids: [],
        });
      } else if (value === 'mobile-apps-selected') {
        onChange({
          should_notify_about_all_mobile_apps: false,
          should_notify_about_mobile_app_ids: [],
        });
      }
    },
    [onChange],
  );

  const classes = useStyles();

  return (
    <RadioGroup
      value={radioGroupSelected}
      onChange={(e, value) => {
        handleOnChangeRadioGroup(value);
      }}
    >
      <Grid item xs={12}>
        <Text
          variant="body"
          style={{ marginTop: 0, marginBottom: 0, margin: 0 }}
        >
          <FormControlLabel
            value="mobile-apps-none"
            control={<Radio color="primary" />}
            label="None"
          />
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Text
          variant="body"
          style={{ marginTop: 0, marginBottom: 0, margin: 0 }}
        >
          <FormControlLabel
            value="mobile-apps-all"
            control={<Radio color="primary" />}
            label="All Mobile Apps"
          />
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Text
          variant="body"
          style={{ marginTop: 0, marginBottom: 0, margin: 0 }}
        >
          <FormControlLabel
            value="mobile-apps-selected"
            control={<Radio color="primary" />}
            label={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Selected Mobile Apps{' '}
                {radioGroupSelected === 'mobile-apps-selected' &&
                  (!pagination_info ? (
                    <Skeleton
                      variant="rect"
                      width={100}
                      height={20}
                      animation="pulse"
                      style={{ marginLeft: 8 }}
                    />
                  ) : (
                    <>
                      ({should_notify_about_mobile_app_ids.length}/
                      {pagination_info.total_count} selected)
                    </>
                  ))}
              </span>
            }
          />
          {radioGroupSelected === 'mobile-apps-selected' && (
            <>
              {mobileAppsListError ? (
                /* Error State */
                <Message
                  variant="error"
                  message={mobileAppsListError.message}
                />
              ) : (
                /* Happy State */
                <div className={classes.multipleAppSelectBackground}>
                  {mobile_applications?.map(app => {
                    const mobileAppMetadataSummary = calculateMobileAppMetadataSummary(
                      app,
                    );

                    return (
                      <div key={app.id}>
                        <AdvancedCheckbox
                          ariaLabel={`Mobile Application ${app.name}`}
                          primaryText={
                            <Text
                              variant="body"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 0,
                                marginBottom: 0,
                                margin: 0,
                              }}
                            >
                              <MobileAppsPlatformIcon
                                platform={app.platform}
                                style={{ padding: 2, marginRight: 4 }}
                              />
                              {app.name}
                            </Text>
                          }
                          secondaryText={mobileAppMetadataSummary}
                          checked={should_notify_about_mobile_app_ids.includes(
                            app.id,
                          )}
                          onChange={e => {
                            (value => {
                              onChange({
                                should_notify_about_all_mobile_apps,
                                should_notify_about_mobile_app_ids: value
                                  ? [
                                      app.id,
                                      ...should_notify_about_mobile_app_ids,
                                    ]
                                  : should_notify_about_mobile_app_ids.filter(
                                      id => id !== app.id,
                                    ),
                              });
                            })(e.target.checked);
                          }}
                        />
                      </div>
                    );
                  })}

                  {/* Loading State */}
                  {mobileAppsListLoading && (
                    <div style={{ marginTop: 8 }}>
                      {[0, 1].map(i => (
                        <Skeleton
                          key={i}
                          variant={'rect'}
                          width={'100%'}
                          height={30}
                          animation={'pulse'}
                          style={{ marginBottom: 4 }}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Text>
      </Grid>
    </RadioGroup>
  );
};

export const MobileAppsSelector = MobileAppsSelectorComponent;
